  // HtmlEditor.tsx
  import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
  import { Editor } from "@tinymce/tinymce-react";
  import axios from "../api/axiosInstance";
  import type { AxiosProgressEvent } from "axios";
  // Якщо є декларації в tinymce:
  import type { Editor as TinyMCEEditor } from "tinymce"; 

  // Оголошуємо власний тип UploadHandler
  type UploadHandler = (
    blobInfo: { blob: () => Blob; filename: () => string }, 
    progress: (percent: number) => void
  ) => Promise<string>;

  interface HtmlEditorProps {
    initialValue?: string;
    onEditorChange: (content: string) => void;
  }

  const HtmlEditor = forwardRef((props: HtmlEditorProps, ref) => {
    const { initialValue = "<p>Почніть писати...</p>", onEditorChange } = props;

    const editorRef = useRef<TinyMCEEditor | null>(null);
    const [content, setContent] = useState(initialValue);

    // Новий UploadHandler (TinyMCE 6+)
    const imageUploadHandler: UploadHandler = async (blobInfo, progress) => {
      const file = blobInfo.blob();
      const filename = blobInfo.filename();

      const formData = new FormData();
      formData.append("file", file, filename);

      const response = await axios.post("/api/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (e.total) {
            const percent = Math.round((e.loaded / e.total) * 100);
            progress(percent);
          }
        },
      });
      // Повертаємо URL, TinyMCE сам замінить base64 -> URL
      return response.data.fileUrl; 
    };

    useImperativeHandle(ref, () => ({
      async uploadAllImages() {
        if (editorRef.current) {
          // Викликаємо внутрішній метод uploadImages()
          await editorRef.current.uploadImages();
        }
      },
      getFinalContent() {
        return editorRef.current?.getContent() ?? content;
      },
    }));

    return (
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey="mofi21tufuj3ch7ppssds3ig28l68zxp2wf6dq26qsfuu7dr"
        value={content}
        onEditorChange={(html) => {
          setContent(html);
          onEditorChange(html);
        }}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "image",
            "media"
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist outdent indent | removeformat | help | image",

          // Вмикаємо вставку base64 (потім заміняється)
          paste_data_images: true,
          images_replace_blob_uris: true,
          automatic_uploads: true,
          convert_urls: false,
          // Оголошуємо UploadHandler (TinyMCE 6)
          images_upload_handler: imageUploadHandler
        }}
      />
    );
  });

  export default HtmlEditor;
