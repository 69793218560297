// src/pages/PostDetailPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Spin, Button, Tag } from 'antd';
import axios from '../../api/axiosInstance';

interface PostDetail {
  _id: string;
  title: string;
  content: string; // HTML, де <img src="/api/protected-file/..." /> тощо
  tags: string[];
}

const PostDetailPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [post, setPost] = useState<PostDetail | null>(null);
  const [loading, setLoading] = useState(false);

  // Тут зберігаємо HTML із підміненою src на Blob URL
  const [safeHtml, setSafeHtml] = useState<string>("");

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios.get(`/api/posts/${id}`)
        .then((res) => {
          setPost(res.data);
        })
        .catch(() => {
          // handle error
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  /**
   * Функція, що знаходить усі src="..." у <img> та <video>,
   * робить запит із токеном, отримує blob і замінює src на blob URL.
   */
  const convertMediaToBlobs = async (html: string): Promise<string> => {
    // Шукаємо всі src="..."
    const pattern = /<(img|video)\s[^>]*src="([^"]+)"[^>]*>/gim;
    let match: RegExpExecArray | null;
    let updatedHtml = html;

    while ((match = pattern.exec(html)) !== null) {
      const oldSrc = match[2]; // "/api/protected-file/123" чи щось таке

      try {
        // 1) Отримуємо файл як blob (передаємо заголовок Authorization, якщо потрібно)
        const response = await axios.get( '/api' + oldSrc, {
          responseType: 'blob',
        });

        // 2) Створюємо blob URL
        const blobUrl = URL.createObjectURL(response.data);

        // 3) Заміна у HTML
        updatedHtml = updatedHtml.replace(oldSrc, blobUrl);
      } catch (err) {
        console.error("Cannot load file from", oldSrc, err);
      }
    }
    return updatedHtml;
  };

  // Коли post змінюється (отримали з бекенду),
  // викликаємо convertMediaToBlobs, щоб підмінити src на blob:... 
  useEffect(() => {
    if (post && post.content) {
      convertMediaToBlobs(post.content).then((htmlWithBlobs) => {
        setSafeHtml(htmlWithBlobs);
      });
    }
  }, [post]);

  if (loading || !post) return <Spin />;

  return (
    <div style={{ padding: 24 }}>
      <Button type="link">
        <Link to="/">← Назад</Link>
      </Button>
      <h2>{post.title}</h2>
      <div style={{ marginBottom: 16 }}>
        {post.tags.map((t) => <Tag key={t}>{t}</Tag>)}
      </div>
      {/* Відображаємо HTML із blob URL */}
      <div dangerouslySetInnerHTML={{ __html: safeHtml }} />
    </div>
  );
};

export default PostDetailPage;
