// src/pages/PostsListPage.tsx
import React, { useState, useEffect, useContext } from 'react';
import { List, Input, Tag, Typography, Space, Spin, Row, Col, Button, Popconfirm, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../../api/axiosInstance';
import { AuthContext } from '../../context/AuthContext';

const { Title } = Typography;
const { Search } = Input;

interface Post {
  _id: string;
  title: string;
  tags: string[];
  updatedAt: string;
}

const PostsListPage: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTitle, setSearchTitle] = useState<string>('');
  const [searchTag, setSearchTag] = useState<string>('');
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const params: any = {};
      if (searchTitle) {
        params.search = searchTitle;
      }
      if (searchTag) {
        params.tag = searchTag;
      }
      const response = await axios.get('/api/posts', { params });
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching posts", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    fetchPosts();
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/api/posts/${id}`);
      message.success('Пост успішно видалено');
      fetchPosts();
    } catch (error) {
      message.error('Не вдалося видалити пост');
    }
  };

  const handleEdit = (id: string) => {
    navigate(`/posts/edit/${id}`);
  };

  const handleCreate = () => {
    navigate(`/posts/new`);
  };

  return (
    <div style={{ padding: '24px' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Title level={2}>FAQ</Title>
        {authContext?.isAuthenticated && authContext?.userRole === 'admin' && (
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
            Створити новий пост
          </Button>
        )}
      </Row>
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Search
            placeholder="Пошук за заголовком"
            enterButton="Пошук"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            onSearch={onSearch}
          />
        </Col>
        <Col span={8}>
          <Search
            placeholder="Пошук за тегами"
            enterButton="Пошук"
            value={searchTag}
            onChange={(e) => setSearchTag(e.target.value)}
            onSearch={onSearch}
          />
        </Col>
        <Col span={8}>
          <Button type="primary" onClick={onSearch}>
            Застосувати фільтри
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Spin size="large" />
      ) : (
        <List
          itemLayout="vertical"
          dataSource={posts}
          renderItem={(post) => (
            <List.Item
              key={post._id}
              actions={
                authContext?.isAuthenticated && authContext?.userRole === 'admin'
                  ? [
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(post._id)}
                        key="edit"
                      />,
                      <Popconfirm
                        title="Ви впевнені, що хочете видалити цей пост?"
                        onConfirm={() => handleDelete(post._id)}
                        okText="Так"
                        cancelText="Ні"
                        key="delete"
                      >
                        <Button type="link" danger icon={<DeleteOutlined />} />
                      </Popconfirm>,
                    ]
                  : []
              }
            >
              <List.Item.Meta
                title={<Link to={`/posts/${post._id}`}>{post.title}</Link>}
                description={
                  <Space>
                    {post.tags.map((tag) => (
                      <Tag key={tag}>{tag}</Tag>
                    ))}
                    <span>Останнє оновлення: {new Date(post.updatedAt).toLocaleString()}</span>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default PostsListPage;
