import React, { useRef, useState, useEffect } from 'react';
import { Form, Input, Button, Spin, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axiosInstance';
import HtmlEditor from '../../components/HtmlEditor';

const PostFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios.get(`/api/posts/${id}`)
        .then((res) => {
          const { title, content, tags } = res.data;
          form.setFieldsValue({
            title,
            tags: tags ? tags.join(', ') : '',
          });
          setEditorContent(content);
        })
        .catch(() => message.error("Не вдалося завантажити пост"))
        .finally(() => setLoading(false));
    }
  }, [id, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      // 1. Завантажуємо всі вставлені зображення (base64 -> URL)
      await editorRef.current?.uploadAllImages();

      // 2. Отримуємо фінальний контент
      const finalContent = editorRef.current?.getFinalContent() || editorContent;

      const payload = {
        title: values.title,
        content: finalContent,
        tags: values.tags.split(',').map((t: string) => t.trim()).filter((t: string) => t !== '')
      };

      if (id) {
        await axios.put(`/api/posts/${id}`, payload);
        message.success("Пост успішно оновлено");
        navigate(`/posts/${id}`);
      } else {
        const res = await axios.post('/api/posts', payload);
        message.success("Пост успішно створено");
        navigate(`/posts/${res.data._id}`);
      }
    } catch (err) {
      message.error("Не вдалося зберегти пост");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spin />;

  return (
    <div style={{ padding: 24, maxWidth: 800, margin: '0 auto' }}>
      <h2>{id ? 'Редагувати пост' : 'Додати пост'}</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="title" label="Заголовок" rules={[{ required: true, message: 'Введіть заголовок' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="tags" label="Теги (через кому)">
          <Input />
        </Form.Item>
        <Form.Item label="Контент">
          <HtmlEditor
            ref={editorRef}
            initialValue={editorContent}
            onEditorChange={(html: string) => setEditorContent(html)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {id ? 'Оновити пост' : 'Створити пост'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PostFormPage;
